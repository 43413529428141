import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import URLSearchParams from '@ungap/url-search-params';
import KonsregSearchBox from './konsreg/konsregSearchBox';
import KonsregSearchResults from './konsreg/konsregSearchResults';
import konsregReducer, { getInitialState } from './reducers/konsregReducer';
import { setScrollPosition } from './util/persistScroll';

export const NAME = 'konsreg';

function shouldSetStateFromParams() {
    return location.search !== '';
}

function populateInitialState() {
    const initialState = getInitialState();
    if (shouldSetStateFromParams()) {
        const params = new URLSearchParams(location.search.substring(1));
        initialState.searchBox.queryString = params.get('q') || '';
    }
    return initialState;
}

export function init() {
    setScrollPosition(NAME);

    const initialState = populateInitialState();

    const store = createStore(konsregReducer, initialState, applyMiddleware(thunk));

    const targets = [
        { rootElemId: 'js-konsregList', component: KonsregSearchResults },
        {
            rootElemId: 'js-konsregSearchbox',
            component: KonsregSearchBox,
            props: { searchOnMount: shouldSetStateFromParams() },
        },
    ];

    targets.forEach((e) => {
        const rootElement = document.getElementById(e.rootElemId);
        render(
            <Provider store={store}>
                <e.component {...e.props} />
            </Provider>,
            rootElement
        );
    });
}
