import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { setSearchQuery } from '../actions/konsregActions';
import SearchBox from '../components/search/searchBox';
import { translate } from '../translate';

const KonsregSearchBox = ({ dispatch, searchBox, searchOnMount }) => (
    <SearchBox
        initialQuery={searchBox.queryString}
        onSearch={(query, isChanged) => dispatch(setSearchQuery(query, isChanged))}
        placeholderText={translate('licenseRegistry.search.placeholder')}
        searchOnMount={searchOnMount}
    />
);

function mapStateToProps(state) {
    return { searchBox: state.searchBox };
}

KonsregSearchBox.propTypes = {
    searchBox: PropTypes.object,
    searchOnMount: PropTypes.bool,
};

export default connect(mapStateToProps)(KonsregSearchBox);
