import contentDisposition from 'content-disposition';
import { trackEvent } from '../eventTracking';
import { govDirection, reportType } from '../reducers/konsregCustomReportReducer';
import { getLocale } from '../translate';
import { downloadFile } from '../util/downloadFile';
import { mapToUrlParams, removeUrlParams, replaceUrlParams } from '../util/urlHelpers';

export const SELECT_TYPEFILTER = 'SELECT_TYPEFILTER';
export const SELECT_VIRKSOMHET_TYPE = 'SELECT_VIRKSOMHET_TYPE';
export const SELECT_GOV_DIRECTION = 'SELECT_GOV_DIRECTION';
export const SELECT_COUNTRYFILTER = 'SELECT_COUNTRYFILTER';
export const SELECT_COUNTYFILTER = 'SELECT_COUNTYFILTER';
export const SELECT_CLASSFILTER = 'SELECT_CLASSFILTER';

export const TOGGLE_INCLUDE_KONSESJONSHAVERE = 'TOGGLE_INCLUDE_KONSESJONSHAVERE';
export const TOGGLE_INCLUDE_AGENTER = 'TOGGLE_INCLUDE_AGENTER';
export const TOGGLE_INCLUDE_FILIALER = 'TOGGLE_INCLUDE_FILIALER';

export const COUNTRIES_LOADED = 'COUNTRIES_LOADED';
export const COUNTIES_LOADED = 'COUNTIES_LOADED';
export const TYPES_LOADED = 'TYPES_LOADED';
export const CLASSES_LOADED = 'CLASSES_LOADED';

export const SUBMIT_CLICKED = 'SUBMIT_CLICKED';
export const RESET_FILTERS = 'RESET_FILTERS';

export const REPORT_REQUESTED = 'REPORT_REQUESTED';
export const REPORT_RECEIVED = 'REPORT_RECEIVED';
export const REPORT_FAILED = 'REPORT_FAILED';

export function getUrlParams(values) {
    const {
        licenseType,
        includeKonsesjonshavere,
        includeAgenter,
        includeFilialer,
        licenseClass,
        country,
        county,
    } = values;

    if (values.reportType === reportType.GOV) {
        return mapToUrlParams((addIfNotNull) => {
            addIfNotNull('t', licenseType);
            addIfNotNull('lc', licenseClass);
            addIfNotNull('gov', values.govDirection);
            addIfNotNull('iso3', country);
            addIfNotNull('k', includeKonsesjonshavere ? null : 'false');
            addIfNotNull('a', includeAgenter ? null : 'false');
            addIfNotNull('f', includeFilialer ? null : 'false');
        });
    }

    return mapToUrlParams((addIfNotNull) => {
        addIfNotNull('t', licenseType);
        addIfNotNull('lc', licenseClass);
        addIfNotNull('c', county);
        addIfNotNull('k', includeKonsesjonshavere ? null : 'false');
        addIfNotNull('a', includeAgenter ? null : 'false');
        addIfNotNull('f', includeFilialer ? null : 'false');
    });
}

export function getReport(values, isValid) {
    const gaEventName = 'Lag rapport';
    return (dispatch) => {
        dispatch({ type: SUBMIT_CLICKED });
        if (!isValid) {
            trackEvent(gaEventName, 'Filtervalg var ugyldig');
            return;
        }

        trackEvent(gaEventName, 'Forsøker å generere rapport');
        const queryString = getUrlParams(values);
        const separator = queryString ? '&' : '?';

        replaceUrlParams(queryString);
        const reportUrl = `/api/konsreg/report/${queryString}${separator}l=${getLocale()}`;
        dispatch({ type: REPORT_REQUESTED });
        fetch(reportUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                response.blob().then((blob) => {
                    const mimeType = response.headers.get('Content-Type');
                    const disposition = contentDisposition.parse(
                        response.headers.get('Content-Disposition')
                    );
                    const fileName = disposition.parameters.filename;
                    downloadFile(blob, fileName, mimeType);
                    dispatch({ type: REPORT_RECEIVED });
                });
            })
            .catch((error) => {
                dispatch({ type: REPORT_FAILED });
                throw error;
            });
    };
}

export function selectTypeFilter(id, name) {
    return (dispatch) => {
        trackEvent('Velg konsesjonstype', name);
        dispatch({
            type: SELECT_TYPEFILTER,
            id,
        });
        dispatch(fetchClasses(id, getLocale()));
    };
}

export function selectReportType(value) {
    const actionName = 'Velg type virksomhet';
    const actionValue = {
        [reportType.NORWAY]: 'Virksomhet i Norge med konsesjon fra Finanstilsynet',
        [reportType.GOV]: 'Grensekryssende virksomhet',
    };

    return (dispatch) => {
        trackEvent(actionName, actionValue[value]);
        dispatch({
            type: SELECT_VIRKSOMHET_TYPE,
            value,
        });
    };
}

export function selectGovDirection(value) {
    const actionName = 'Velg retning på grensekryssende virksomhet';
    const actionValue = {
        [govDirection.OUT]: 'Ut av Norge',
        [govDirection.IN]: 'Inn til Norge',
    };

    return (dispatch) => {
        trackEvent(actionName, actionValue);
        dispatch({
            type: SELECT_GOV_DIRECTION,
            value,
        });
    };
}

export function toggleIncludeKonsesjonshavere(value) {
    return (dispatch) => {
        trackEvent('Inkluder konsesjonshavere', value);
        dispatch({
            type: TOGGLE_INCLUDE_KONSESJONSHAVERE,
            value,
        });
    };
}

export function toggleIncludeAgenter(value) {
    return (dispatch) => {
        trackEvent('Inkluder agenter', value);
        dispatch({
            type: TOGGLE_INCLUDE_AGENTER,
            value,
        });
    };
}

export function toggleIncludeFilialer(value) {
    return (dispatch) => {
        trackEvent('Inkluder filialer', value);
        dispatch({
            type: TOGGLE_INCLUDE_FILIALER,
            value,
        });
    };
}

export function selectCountryFilter(id, name) {
    return (dispatch) => {
        trackEvent('Velg land', name);
        dispatch({
            type: SELECT_COUNTRYFILTER,
            id,
        });
    };
}

export function selectCountyFilter(id, name) {
    return (dispatch) => {
        trackEvent('Velg fylke', name);
        dispatch({
            type: SELECT_COUNTYFILTER,
            id,
        });
    };
}

export function selectClassFilter(id, name) {
    return (dispatch) => {
        trackEvent('Velg klasse', name);
        dispatch({
            type: SELECT_CLASSFILTER,
            id,
        });
    };
}

export function fetchGovCountries(lang) {
    return (dispatch) =>
        fetch(`/api/konsreg/govland?l=${lang}`)
            .then((response) => response.json())
            .then((countries) => dispatch({ type: COUNTRIES_LOADED, countries }));
}

export function fetchCounties() {
    return (dispatch) =>
        fetch('/api/konsreg/fylker')
            .then((response) => response.json())
            .then((counties) => dispatch({ type: COUNTIES_LOADED, counties }));
}

export function fetchTypes(lang) {
    return (dispatch) =>
        fetch(`/api/konsreg/konsesjonstyper?l=${lang}`)
            .then((response) => response.json())
            .then((types) => dispatch({ type: TYPES_LOADED, types }));
}

export function fetchClasses(konsesjonType, lang) {
    return (dispatch) =>
        fetch(`/api/konsreg/konsesjonsklasser?konsesjonstype=${konsesjonType}&l=${lang}`)
            .then((response) => response.json())
            .then((classes) => dispatch({ type: CLASSES_LOADED, classes }));
}

export function resetFilters() {
    return (dispatch) => {
        trackEvent('Nullstill filter');
        dispatch({
            type: RESET_FILTERS,
        });
        removeUrlParams();
    };
}
