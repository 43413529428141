import { combineReducers } from 'redux';
import URLSearchParams from '@ungap/url-search-params';
import {
    CLASSES_LOADED,
    COUNTIES_LOADED,
    COUNTRIES_LOADED,
    REPORT_FAILED,
    REPORT_RECEIVED,
    REPORT_REQUESTED,
    RESET_FILTERS,
    SELECT_CLASSFILTER,
    SELECT_COUNTRYFILTER,
    SELECT_COUNTYFILTER,
    SELECT_GOV_DIRECTION,
    SELECT_TYPEFILTER,
    SELECT_VIRKSOMHET_TYPE,
    SUBMIT_CLICKED,
    TOGGLE_INCLUDE_AGENTER,
    TOGGLE_INCLUDE_FILIALER,
    TOGGLE_INCLUDE_KONSESJONSHAVERE,
    TYPES_LOADED,
} from '../actions/konsregCustomReportActions';

export const reportType = {
    NORWAY: 'norway',
    GOV: 'gov',
};

export const govDirection = {
    OUT: 'out',
    IN: 'in',
};

// Initial states

const getInitialUiState = () => ({
    hasTriedToSubmit: false,
    isGeneratingReport: false,
    reportGenerationFailed: false,
});

const getInitialDataState = () => ({
    licenseTypes: {
        loaded: false,
        items: [],
        groups: [],
    },
    licenseClasses: {
        loaded: false,
        items: [],
        groups: [],
    },
    countries: {
        loaded: false,
        items: [],
    },
    counties: {
        loaded: false,
        items: [],
    },
});

const getInitialValuesState = () => ({
    licenseType: '',
    licenseClass: '',
    reportType: reportType.NORWAY,
    govDirection: govDirection.OUT,
    country: '',
    county: '',
    includeKonsesjonshavere: true,
    includeAgenter: true,
    includeFilialer: true,
});

const getDefaultState = () => ({
    ui: getInitialUiState(),
    data: getInitialDataState(),
    values: getInitialValuesState(),
});

function shouldSetStateFromParams() {
    return location.search !== '';
}

export function getInitialState() {
    const initialState = getDefaultState();
    if (shouldSetStateFromParams()) {
        const params = new URLSearchParams(location.search.substring(1));
        const direction = params.get('gov');
        if (direction) {
            // Grensekryssende virksomhet
            initialState.values.reportType = reportType.GOV;
            initialState.values.govDirection = direction;
            initialState.values.country = params.get('iso3') || '';
        } else {
            // Virksomhet i Norge
            initialState.values.reportType = reportType.NORWAY;
            initialState.values.county = params.get('c') || '';
        }
        // Common
        initialState.values.licenseType = params.get('t') || '';
        initialState.values.licenseClass = params.get('lc') || '';
        initialState.values.includeKonsesjonshavere = params.get('k') !== 'false';
        initialState.values.includeAgenter = params.get('a') !== 'false';
        initialState.values.includeFilialer = params.get('f') !== 'false';
    }
    return initialState;
}

function update(oldState, newState) {
    return { ...oldState, ...newState };
}

function updateAt(oldState, key, newStateSlice) {
    return update(oldState, { [key]: update(oldState[key], newStateSlice) });
}

// Reducers
const ui = (state = getInitialUiState(), action) => {
    switch (action.type) {
        case SUBMIT_CLICKED:
            return update(state, { hasTriedToSubmit: true, reportGenerationFailed: false });
        case REPORT_REQUESTED:
            return update(state, { isGeneratingReport: true });
        case REPORT_RECEIVED:
            return update(state, { isGeneratingReport: false });
        case REPORT_FAILED:
            return update(state, { isGeneratingReport: false, reportGenerationFailed: true });
        case RESET_FILTERS:
        case SELECT_TYPEFILTER:
        case SELECT_VIRKSOMHET_TYPE:
        case TOGGLE_INCLUDE_KONSESJONSHAVERE:
        case TOGGLE_INCLUDE_AGENTER:
        case TOGGLE_INCLUDE_FILIALER:
        case SELECT_COUNTRYFILTER:
        case SELECT_COUNTYFILTER:
        case SELECT_CLASSFILTER:
        case SELECT_GOV_DIRECTION:
            // When the user interacts with the form, hide the error message
            return update(state, { reportGenerationFailed: false });
        default:
            return state;
    }
};

const data = (state = getInitialDataState(), action) => {
    switch (action.type) {
        case TYPES_LOADED:
            return updateAt(state, 'licenseTypes', {
                loaded: true,
                items: action.types.map((type) =>
                    update(type, { selected: state.activeFilter === type.id })
                ),
                groups: [...new Set(action.types.map((type) => type.group))],
            });
        case COUNTRIES_LOADED:
            return updateAt(state, 'countries', {
                loaded: true,
                items: action.countries.map((country) =>
                    update(country, { selected: state.activeFilter === country.id })
                ),
            });
        case COUNTIES_LOADED:
            return updateAt(state, 'counties', {
                loaded: true,
                items: action.counties.map((county) =>
                    update(county, { selected: state.activeFilter === county.id })
                ),
            });
        case CLASSES_LOADED:
            return updateAt(state, 'licenseClasses', {
                loaded: true,
                items: action.classes,
                groups: [...new Set(action.classes.map((type) => type.group))],
            });
        case RESET_FILTERS:
            // TODO: Not sure why class groups was reset in the original code. Maybe it is unnecessary?
            return updateAt(state, 'licenseClasses', {
                groups: [],
            });
        default:
            return state;
    }
};

const values = (state = getInitialValuesState(), action) => {
    switch (action.type) {
        case SELECT_TYPEFILTER:
            return update(state, { licenseType: action.id, licenseClass: '' });
        case SELECT_CLASSFILTER:
            return update(state, { licenseClass: action.id });
        case SELECT_VIRKSOMHET_TYPE:
            return update(state, { reportType: action.value });
        case SELECT_GOV_DIRECTION:
            return update(state, { govDirection: action.value });
        case TOGGLE_INCLUDE_KONSESJONSHAVERE:
            return update(state, { includeKonsesjonshavere: action.value });
        case TOGGLE_INCLUDE_AGENTER:
            return update(state, { includeAgenter: action.value });
        case TOGGLE_INCLUDE_FILIALER:
            return update(state, { includeFilialer: action.value });
        case SELECT_COUNTRYFILTER:
            return update(state, { country: action.id });
        case SELECT_COUNTYFILTER:
            return update(state, { county: action.id });
        case RESET_FILTERS:
            return getInitialValuesState();
        default:
            return state;
    }
};

// Combine and export

export const reducer = combineReducers({
    ui,
    data,
    values,
});
