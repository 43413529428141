import { createSelector } from 'reselect';

const queryStringSelector = (state) => state.searchBox.queryString;
const settingsSelector = (state) => state.settings;

export const konsregSearchStateSelector = createSelector(
    queryStringSelector,
    settingsSelector,
    (queryString, settings) => ({
        queryString,
        lang: settings.lang,
        showResults: queryString !== '',
    })
);
