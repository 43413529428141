import contentDisposition from 'content-disposition';
import { getLocale } from '../translate';
import { downloadFile } from '../util/downloadFile';

export const predefinedReports = {
    Nominee: 'Nominee',
    DomesticActivityBank: 'DomesticActivityBank',
    DomesticActivityInsurance: 'DomesticActivityInsurance', // NET-779: Skatteetaten bruker denne rapporten direkte med lenke https://www.finanstilsynet.no/api/konsreg/report/standard?report=DomesticActivityInsurance&lang=no. Hvis noe endres ifm. denne, må disse varsles.
};

export const PREDEFINED_REPORT_REQUESTED = 'PREDEFINED_REPORT_REQUESTED';
export const PREDEFINED_REPORT_RECEIVED = 'PREDEFINED_REPORT_RECEIVED';
export const PREDEFINED_REPORT_FAILED = 'PREDEFINED_REPORT_FAILED';

export function getPredefinedReport(reportType) {
    return (dispatch) => {
        dispatch({ type: PREDEFINED_REPORT_REQUESTED, payload: { reportType } });
        fetch(`/api/konsreg/report/standard?report=${reportType}&lang=${getLocale()}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then((response) => {
                response.blob().then((blob) => {
                    const mimeType = response.headers.get('Content-Type');
                    const disposition = contentDisposition.parse(
                        response.headers.get('Content-Disposition')
                    );
                    const fileName = disposition.parameters.filename;
                    downloadFile(blob, fileName, mimeType);
                    dispatch({ type: PREDEFINED_REPORT_RECEIVED, payload: { reportType } });
                });
            })
            .catch((error) => {
                dispatch({ type: PREDEFINED_REPORT_FAILED, payload: { reportType } });
                throw error;
            });
    };
}
