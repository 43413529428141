import { trackEvent } from '../eventTracking';
import { konsregSearchStateSelector } from '../konsreg/konsregSearchStateSelector';
import { getLocale } from '../translate';
import { clearSessionStorage, getSessionStorageResult } from '../util/persistScroll';
import { mapToUrlParams, replaceUrlParams } from '../util/urlHelpers';

export const SEARCH_REQUESTED = 'SEARCH_REQUESTED';
export const SEARCH_LOADED = 'SEARCH_LOADED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const LOADED_MORE = 'LOADED_MORE';

export function getUrlParams(searchState) {
    const { queryString } = searchState;

    return mapToUrlParams((addIfNotNull) => {
        addIfNotNull('q', queryString);
    });
}

function searchRequested() {
    return {
        type: SEARCH_REQUESTED,
    };
}

function searchLoaded(searchResult) {
    return {
        type: SEARCH_LOADED,
        searchResult,
    };
}

function search(searchState) {
    let searchApiUrl = '/api/konsreg/search';
    const queryString = getUrlParams(searchState);
    const separator = queryString ? '&' : '?';

    replaceUrlParams(queryString);
    searchApiUrl += `${queryString}${separator}l=${getLocale()}`;

    const konsregResult = getSessionStorageResult('konsregResult');

    if (konsregResult) {
        return (dispatch) => {
            dispatch(searchLoaded(konsregResult));
        };
    }
    return (dispatch) => {
        dispatch(searchRequested());

        return fetch(searchApiUrl).then((response) =>
            response.json().then((result) => dispatch(searchLoaded(result)))
        );
    };
}

export function setSearchQuery(queryString, isChanged) {
    // Clear sessionStorage hvis query er endret for å få nye resultater
    if (isChanged) {
        clearSessionStorage();
    }
    return (dispatch, getState) => {
        trackEvent('Søk', queryString);
        dispatch({
            type: SET_SEARCH_QUERY,
            queryString,
        });
        const searchState = konsregSearchStateSelector(getState());
        dispatch(search(searchState));
    };
}

export function loadedMore(searchResult) {
    trackEvent('Last flere', `Side ${searchResult.page}`);
    return {
        type: LOADED_MORE,
        searchResult,
    };
}

export function loadMore(searchState, currentPage) {
    const searchApi = '/api/konsreg/search';
    const urlParams = getUrlParams(searchState);
    const separator = urlParams ? '&' : '?';

    const q = `${urlParams}${separator}p=${currentPage + 1}&l=${searchState.lang}`;

    return (dispatch) =>
        fetch(searchApi + q).then((response) =>
            response.json().then((result) => dispatch(loadedMore(result)))
        );
}
