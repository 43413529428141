import classNames from 'classnames';
import React, { Component } from 'react';
import Collapse from 'react-collapse';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
    fetchClasses,
    fetchCounties,
    fetchGovCountries,
    fetchTypes,
    getReport,
    resetFilters,
    selectClassFilter,
    selectCountryFilter,
    selectCountyFilter,
    selectGovDirection,
    selectReportType,
    selectTypeFilter,
    toggleIncludeAgenter,
    toggleIncludeFilialer,
    toggleIncludeKonsesjonshavere,
} from '../actions/konsregCustomReportActions';
import { Checkbox } from '../components/Checkbox';
import Loader from '../components/loader';
import { RadioButton } from '../components/RadioButton';
import { Tooltip, tooltipContainerId } from '../components/Tooltip';
import { govDirection, reportType } from '../reducers/konsregCustomReportReducer';
import { getLocale, translate } from '../translate';
import KonsregSelectList from './konsregSelectList';

function validateVirksomhetstyper(values) {
    const isValid = [
        values.includeKonsesjonshavere,
        values.includeFilialer,
        values.includeAgenter,
    ].some((x) => x);
    return { virksomhetstypeFilters: isValid };
}

function validateKonsesjontypeOrFylkeOrLand(values) {
    const isValid = [
        values.licenseType,
        values.reportType === reportType.NORWAY ? values.county : values.country,
    ].some((x) => x !== '');
    return { hasSelectedKonsesjontypeOrFylkeOrLand: isValid };
}

function validate(values) {
    return {
        ...validateKonsesjontypeOrFylkeOrLand(values),
        ...validateVirksomhetstyper(values),
    };
}

function isAllValid(validationResult) {
    // "validationResult" has to be a dictionary string -> bool
    return Object.values(validationResult).every((x) => x);
}

function getValidationMessages(values, validationResult) {
    const validationMessages = [];
    if (!validationResult.virksomhetstypeFilters) {
        validationMessages.push(
            translate('licenseRegistry.reports.validation.missingVirksomhetstype')
        );
    }
    if (
        !validationResult.hasSelectedKonsesjontypeOrFylkeOrLand &&
        values.reportType === reportType.NORWAY
    ) {
        validationMessages.push(
            translate('licenseRegistry.reports.validation.missingKonsesjontypeOrFylke')
        );
    }
    if (
        !validationResult.hasSelectedKonsesjontypeOrFylkeOrLand &&
        values.reportType === reportType.GOV
    ) {
        validationMessages.push(
            translate('licenseRegistry.reports.validation.missingKonsesjontypeOrLand')
        );
    }
    return validationMessages;
}

class KonsregCustomReport extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchGovCountries(this.props.lang));
        dispatch(fetchCounties());
        dispatch(fetchTypes(this.props.lang));
        if (this.props.values.licenseType !== '') {
            dispatch(fetchClasses(this.props.values.licenseType, this.props.lang));
        }
    }

    render() {
        const { ui, data, values, dispatch } = this.props;
        const validationResult = validate(values);
        const validationMessages = getValidationMessages(values, validationResult);
        const isFormValid = isAllValid(validationResult);
        const canSubmit = !ui.hasTriedToSubmit || isFormValid;
        const invalidKonsesjontypeFylkeLand =
            ui.hasTriedToSubmit && !validationResult.hasSelectedKonsesjontypeOrFylkeOrLand;

        const showClassFilters =
            data.licenseClasses.loaded && data.licenseClasses.groups.length > 0;

        return !this.props.showFilters ? (
            <Loader isLoading languageCode={this.props.lang} />
        ) : (
            <div className='article--bodytext mb-2' >
                <ReactTooltip id={tooltipContainerId} />
                <div className="licenseregistry-search-filter-header-container">
                    <div className="u-full-width konsreg-filter-header">
                        <h3 className='text-lg font-semibold'>{translate('licenseRegistry.reports.headers.customReport')}</h3>
                    </div>
                </div>

                <FormSection>
                    {data.licenseTypes.loaded && (
                        <div>
                            <h4 className="text-lg font-semibold mb-2">
                                {translate('licenseRegistry.headers.typefilter')}
                            </h4>
                            <KonsregSelectList
                                label={translate('licenseRegistry.headers.typefilter')}
                                defaultOptionText={translate(
                                    'licenseRegistry.labels.allLicenseTypes'
                                )}
                                filters={data.licenseTypes.items}
                                groups={data.licenseTypes.groups}
                                activeFilter={values.licenseType}
                                onSelect={(id, name) => dispatch(selectTypeFilter(id, name))}
                                invalid={invalidKonsesjontypeFylkeLand}
                            />
                        </div>
                    )}

                    <Collapse isOpened={showClassFilters}>
                        {showClassFilters && (
                            <div>
                                <h4 className="text-lg font-semibold mb-2">
                                    {translate('licenseRegistry.headers.classfilter')}
                                </h4>
                                <KonsregSelectList
                                    label={translate('licenseRegistry.headers.classfilter')}
                                    defaultOptionText={translate(
                                        'licenseRegistry.labels.allClasses'
                                    )}
                                    filters={data.licenseClasses.items}
                                    groups={data.licenseClasses.groups}
                                    activeFilter={values.licenseClass}
                                    onSelect={(id, name) => dispatch(selectClassFilter(id, name))}
                                />
                            </div>
                        )}
                    </Collapse>
                </FormSection>

                <FormSection className="konsreg-reports-col flex flex-wrap justify-left">
                    <RadioButton
                        group="virksomhet-type"
                        value={reportType.NORWAY}
                        checked={values.reportType === reportType.NORWAY}
                        onChange={(e) => dispatch(selectReportType(e.target.value))}
                    >
                        {translate('licenseRegistry.reports.reportType.norway')}
                        <InfoTooltip
                            tooltip={translate('licenseRegistry.reports.infotext.domesticReport')}
                            ariaLabel={`${translate('infoAbout')} ${translate(
                                'licenseRegistry.reports.reportType.norway'
                            )}`}
                        />
                    </RadioButton>
                    <RadioButton
                        group="virksomhet-type"
                        value={reportType.GOV}
                        checked={values.reportType === reportType.GOV}
                        onChange={(e) => dispatch(selectReportType(e.target.value))}
                    >
                        {translate('licenseRegistry.reports.reportType.gov')}
                        <InfoTooltip
                            tooltip={translate('licenseRegistry.reports.infotext.govReport')}
                            ariaLabel={`${translate('infoAbout')} ${translate(
                                'licenseRegistry.reports.reportType.gov'
                            )}`}
                        />
                    </RadioButton>
                </FormSection>

                <FormSection>
                    <ReactCSSTransitionReplace
                        transitionName="konsreg-report-type-filters"
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={150}
                    >
                        <div key={values.reportType}>
                            {values.reportType === reportType.NORWAY && data.counties.loaded && (
                                <div className='mb-6'>
                                    <h4 className="text-lg font-semibold mb-2">
                                        {translate('licenseRegistry.headers.countyfilter')}
                                    </h4>
                                    <KonsregSelectList
                                        label={translate('licenseRegistry.headers.countyfilter')}
                                        show={data.counties.loaded}
                                        defaultOptionText={translate(
                                            'licenseRegistry.labels.allCounties'
                                        )}
                                        filters={data.counties.items}
                                        activeFilter={values.county}
                                        onSelect={(id, name) =>
                                            dispatch(selectCountyFilter(id, name))
                                        }
                                        invalid={invalidKonsesjontypeFylkeLand}
                                    />
                                </div>
                            )}
                            {values.reportType === reportType.GOV && data.countries.loaded && (
                                <div className='mb-6'>
                                    <div className='mb-2'>
                                        <RadioButton
                                            group="gov-direction"
                                            value={govDirection.OUT}
                                            checked={values.govDirection === govDirection.OUT}
                                            onChange={(e) =>
                                                dispatch(selectGovDirection(e.target.value))
                                            }
                                        >
                                            {translate('licenseRegistry.reports.to')}
                                        </RadioButton>
                                        <RadioButton
                                            group="gov-direction"
                                            value={govDirection.IN}
                                            checked={values.govDirection === govDirection.IN}
                                            onChange={(e) =>
                                                dispatch(selectGovDirection(e.target.value))
                                            }
                                        >
                                            {translate('licenseRegistry.reports.from')}
                                        </RadioButton>
                                    </div>
                                    <h4 className="text-lg font-semibold mb-2">
                                        {translate('licenseRegistry.headers.countryFilter')}
                                    </h4>
                                    <KonsregSelectList
                                        label={translate('licenseRegistry.headers.countryFilter')}
                                        defaultOptionText={translate(
                                            'licenseRegistry.labels.foreignCountries'
                                        )}
                                        filters={data.countries.items}
                                        activeFilter={values.country}
                                        onSelect={(id, name) =>
                                            dispatch(selectCountryFilter(id, name))
                                        }
                                        invalid={invalidKonsesjontypeFylkeLand}
                                    />
                                </div>
                            )}
                        </div>
                    </ReactCSSTransitionReplace>
                </FormSection>

                <ForetakTypeFilters
                    values={values}
                    invalid={ui.hasTriedToSubmit && !validationResult.virksomhetstypeFilters}
                    dispatch={dispatch}
                />

                {ui.hasTriedToSubmit && <ValidationError messages={validationMessages} />}

                <FormSection className="konsreg-reports-row flex justify-left space-x-6">
                    <button
                        type="button"
                        className="btn btn--primary icon icon--file__download"
                        onClick={() => dispatch(getReport(values, isFormValid))}
                        disabled={!canSubmit || ui.isGeneratingReport}
                    >
                        {ui.isGeneratingReport && (
                            <img
                                className="max-h-[16px] animate-spin"
                                src="/Content/Images/spinner-white.svg"
                                alt=""
                                height="16"
                            />
                        )}
                        {ui.isGeneratingReport
                            ? translate('licenseRegistry.reports.isGeneratingReport')
                            : translate('licenseRegistry.reports.generateReport')}
                    </button>
                    <button
                        type="button"
                        className="btn btn--secondary"
                        onClick={() => dispatch(resetFilters())}
                        disabled={ui.isGeneratingReport}
                    >
                        {translate('licenseRegistry.reports.resetFilters')}
                    </button>
                </FormSection>
                {ui.reportGenerationFailed && (
                    <div className="konsreg-reports-error">
                        {translate('licenseRegistry.reports.reportGenerationFailed')}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(store) {
    const state = store.customReport;
    return {
        ui: state.ui,
        data: state.data,
        values: state.values,
        lang: getLocale(),
        showFilters: state.data.licenseTypes.loaded,
    };
}

export default connect(mapStateToProps)(KonsregCustomReport);

const ForetakTypeFilters = ({ values, invalid, dispatch }) => (
    <FormSection>
        <h4 className="text-lg font-semibold mb-2">
            {translate('licenseRegistry.headers.virksomhetstypeFilters')}
        </h4>
        <div className="konsreg-reports-row flex justify-left space-x-6">
            <span>
                <Checkbox
                    invalid={invalid}
                    onChange={() =>
                        dispatch(toggleIncludeKonsesjonshavere(!values.includeKonsesjonshavere))
                    }
                    id="includeKonsesjonshavere"
                    checked={values.includeKonsesjonshavere}
                >
                    {translate('licenseRegistry.labels.konsesjonshavereFilter')}
                    <InfoTooltip
                        tooltip={translate(
                            'licenseRegistry.reports.infotext.includeKonsesjonshavere'
                        )}
                        ariaLabel={`${translate('infoAbout')} ${translate(
                            'licenseRegistry.labels.konsesjonshavereFilter'
                        )}`}
                    />
                </Checkbox>
            </span>
            <span>
                <Checkbox
                    invalid={invalid}
                    onChange={() => dispatch(toggleIncludeFilialer(!values.includeFilialer))}
                    id="includeFilialer"
                    checked={values.includeFilialer}
                >
                    {translate('licenseRegistry.labels.filialFilter')}
                    <InfoTooltip
                        tooltip={translate('licenseRegistry.reports.infotext.includeFilialer')}
                        ariaLabel={`${translate('infoAbout')} ${translate(
                            'licenseRegistry.labels.filialFilter'
                        )}`}
                    />
                </Checkbox>
            </span>
            <span>
                <Checkbox
                    invalid={invalid}
                    onChange={() => dispatch(toggleIncludeAgenter(!values.includeAgenter))}
                    id="includeAgenter"
                    checked={values.includeAgenter}
                >
                    {translate('licenseRegistry.labels.agentFilter')}
                    <InfoTooltip
                        tooltip={translate('licenseRegistry.reports.infotext.includeAgenter')}
                        ariaLabel={`${translate('infoAbout')} ${translate(
                            'licenseRegistry.labels.agentFilter'
                        )}`}
                    />
                </Checkbox>
            </span>
        </div>
    </FormSection>
);

const ValidationError = ({ messages }) =>
    messages.length > 0 && (
        <div
            className={classNames('konsreg-reports-validationErrorMessage', {
                multiple: messages.length > 1,
            })}
            role="alert"
        >
            <ul>
                {messages.map((message) => (
                    <li key={message}>{message}</li>
                ))}
            </ul>
        </div>
    );

const InfoTooltip = ({ tooltip, ariaLabel }) => (
    <Tooltip className="konsreg-reports-infoTooltip" tooltip={tooltip} ariaLabel={ariaLabel}>
        <InfoIcon />
    </Tooltip>
);

const InfoIcon = () => (
    <svg viewBox="0 0 4 10" xmlns="http://www.w3.org/2000/svg" className="konsreg-reports-infoIcon">
        <path
            // eslint-disable-next-line max-len
            d="M3.146 8.644a.46.46 0 0 1-.21-.197.65.65 0 0 1-.073-.32V3.84l-.073-.08-2.395.14v.393c.098.011.206.036.325.076.119.04.208.081.266.124a.68.68 0 0 1 .274.561v3.141c0 .134-.021.24-.063.321a.415.415 0 0 1-.22.187.812.812 0 0 1-.21.054c-.082.01-.165.02-.25.027v.394h3.089v-.394a1.345 1.345 0 0 1-.245-.054 1.577 1.577 0 0 1-.215-.087M1.93 2.805c.26 0 .486-.096.676-.289a.958.958 0 0 0 .286-.699.97.97 0 0 0-.283-.701.914.914 0 0 0-.68-.292.922.922 0 0 0-.681.292.966.966 0 0 0-.286.701c0 .273.095.507.286.7.19.192.418.288.682.288"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

const FormSection = ({ children, className }) => (
    <div className={classNames('konsreg-reports-formSection mb-6', className)}>{children}</div>
);
