import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { loadMore } from '../actions/konsregActions';
import LoadMore from '../components/loadMore';
import KonsregSearchResultItems from './konsregSearchResultItems';
import { konsregSearchStateSelector } from './konsregSearchStateSelector';

const KonsregSearchResults = ({ searchState, searchResult, dispatch }) =>
    searchState.showResults ? (
        <div>
            <KonsregSearchResultItems searchResult={searchResult} />
            {searchResult.canLoadMore && !searchResult.loading && (
                <LoadMore
                    onLoadMoreClick={() => dispatch(loadMore(searchState, searchResult.page))}
                />
            )}
        </div>
    ) : (
        <div />
    );

KonsregSearchResults.propTypes = {
    searchResult: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        searchResult: state.searchResult,
        searchState: konsregSearchStateSelector(state),
    };
}

export default connect(mapStateToProps)(KonsregSearchResults);
