import PropTypes from 'prop-types';
import React from 'react';
import { translate } from '../translate';
import KonsregSearchResultItem from './konsregSearchResultItem';

const KonsregSearchResultItems = ({ searchResult }) => {
    const totalHitsText = `${searchResult.totalHits} ${translate(
        'newsarchive.texts.totalHitsText'
    )}`;
    const hitsText =
        searchResult.totalHits > 0
            ? `${searchResult.visibleHits} ${translate(
                  'licenseRegistry.labels.of'
              )} ${totalHitsText}`
            : totalHitsText;

    return searchResult.loading || searchResult.totalHits !== undefined ? (
        <div>
            <div className='bg-white-400 p-normal mb-6'>
                {searchResult.loading ? translate('licenseRegistry.search.searching') : hitsText}
                {searchResult.loading && (
                    <img
                        className=" max-h-[16px] animate-spin"
                        src="/Content/Images/spinner.svg"
                        alt=""
                    />
                )}
            </div>

            <div className='bg-white-400 px-4 pb-4 mb-6'>
            {!searchResult.loading &&
                searchResult.items.map((item, index) => (
                    <KonsregSearchResultItem
                        key={item.id}
                        name={item.name}
                        published={item.published}                        
                        typeDescription={item.typeDescription}
                        url={item.url}
                        country={item.country}
                        searchRank={index + 1}
                    />
                ))}
                </div>
        </div>
    ) : (
        <div />
    );
};

KonsregSearchResultItems.propTypes = {
    searchResult: PropTypes.object.isRequired,
};

export default KonsregSearchResultItems;
