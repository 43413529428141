import React from 'react';
import { connect } from 'react-redux';
import { getPredefinedReport, predefinedReports } from '../actions/konsregPredefinedReportsActions';
import { predefinedReportStates } from '../reducers/konsregPredefinedReportsReducer';
import { translate } from '../translate';

const KonsregPredefinedReports = ({ reportStates, dispatch }) => (
    <div>
        <div >
            <div >
                <h3>{translate('licenseRegistry.reports.headers.predefinedReports')}</h3>
            </div>
        </div>
        <div>
            {Object.values(predefinedReports).map((reportType) => {
                const reportState = reportStates[reportType];
                const heading = translate(
                    `licenseRegistry.reports.predefinedReports.${reportType}.heading`
                );
                const description = translate(
                    `licenseRegistry.reports.predefinedReports.${reportType}.description`
                );
                return (
                    <div key={reportType}>
                        <div>
                            <h4>{heading}</h4>
                            <div>
                                {reportStates.Nominee === predefinedReportStates.Loading && (
                                    <img
                                        className="max-h-[16px] animate-spin"
                                        src="/Content/Images/spinner-blue.svg"
                                        alt=""
                                    />
                                )}
                                <button
                                    type="button"
                                    className="btn btn--primary icon icon--file__download mb-4"
                                    onClick={() => dispatch(getPredefinedReport(reportType))}
                                    disabled={reportState === predefinedReportStates.Loading}
                                >
                                    {reportState === predefinedReportStates.Loading
                                        ? translate('licenseRegistry.reports.isGeneratingReport')
                                        : translate(
                                              'licenseRegistry.reports.downloadPredefinedReport'
                                          )}
                                </button>
                            </div>
                        </div>
                        <p>{description}</p>
                        {reportState === predefinedReportStates.Failed && (
                            <div className="konsreg-reports-error">
                                {translate('licenseRegistry.reports.reportGenerationFailed')}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    </div>
);

function mapStateToProps(state) {
    return { reportStates: state.predefinedReports };
}

export default connect(mapStateToProps)(KonsregPredefinedReports);
