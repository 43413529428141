import classNames from 'classnames';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';

export const tooltipContainerId = 'ftno-react-tooltip';

export class Tooltip extends React.Component {
    constructor() {
        super();
        this.ref = null;
        this.state = {
            isStickyOpen: false,
        };
    }

    render() {
        const { tooltip, tooltipId, className, children, ariaLabel } = this.props;
        const tooltipContent = ReactDOMServer.renderToStaticMarkup(
            <span role="tooltip" id={tooltipId}>
                {tooltip}
            </span>
        );
        return (
            <button
                // ReactTooltip settings
                data-event="focus"
                data-event-off="blur"
                data-html
                data-class="konsreg-reports-tooltip"
                data-tip={tooltipContent}
                data-for={tooltipContainerId}
                data-effect="solid"
                // Other props
                type="button"
                aria-describedby={tooltipId}
                aria-label={ariaLabel}
                className={classNames('konsreg-reports-tooltipButton', className)}
                ref={(element) => {
                    this.ref = element;
                }}
                onClick={(e) => {
                    // Workaround for Internet Explorer.
                    // Needed to avoid "clicking" the label if this Tooltip is within a label
                    e.preventDefault();
                }}
                onFocus={() => {
                    if (this.ref) {
                        this.setState({ isStickyOpen: true });
                    }
                }}
                onBlur={() => {
                    if (this.ref) {
                        this.setState({ isStickyOpen: false });
                    }
                }}
                onMouseEnter={() => {
                    if (this.ref) {
                        ReactTooltip.show(this.ref);
                    }
                }}
                onMouseLeave={() => {
                    if (this.ref && !this.state.isStickyOpen) {
                        ReactTooltip.hide(this.ref);
                    }
                }}
                onKeyDown={(event) => {
                    if (this.ref && event.keyCode === 27) {
                        // ESC
                        ReactTooltip.hide(this.ref);
                        this.setState({ isStickyOpen: false });
                    }
                }}
            >
                {children}
            </button>
        );
    }
}
