export function downloadFile(data, fileName, mimeType) {
    const blob = new Blob([data], { type: mimeType });
    if (window.navigator.msSaveOrOpenBlob) {
        // IE doesn't support download attribute
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const objectUrl = window.URL.createObjectURL(blob);

        const linkElement = document.createElement('a');
        linkElement.style.display = 'none';
        linkElement.href = objectUrl;
        if (typeof linkElement.download !== 'undefined') {
            // Only set the download attribute if it is supported (see https://caniuse.com/#feat=download)
            // Other browsers (notably any iOS browser) *should* try to open the file, but it will have a
            // nonsensical file name.
            linkElement.download = fileName;
        }

        document.body.append(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        // cleanup to avoid memory leak
        setTimeout(() => {
            window.URL.revokeObjectURL(objectUrl);
        }, 100);
    }
}
