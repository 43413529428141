import { combineReducers } from 'redux';
import {
    LOADED_MORE,
    SEARCH_LOADED,
    SEARCH_REQUESTED,
    SET_SEARCH_QUERY,
} from '../actions/konsregActions';
import { getLocale } from '../translate';
import { setSessionStorageResult } from '../util/persistScroll';

// Initial states

const getInitialSettingsState = () => ({
    lang: getLocale(),
    showResults: false,
});

const getInitialSearchBoxState = () => ({
    queryString: '',
});

const getInitialSearchResultState = () => ({
    loading: false,
    items: [],
});

export const getInitialState = () => ({
    settings: getInitialSettingsState(),
    searchBox: getInitialSearchBoxState(),
    searchResult: getInitialSearchResultState(),
});

// Reducers

const settings = (state = getInitialSettingsState()) => state;

const searchBox = (state = getInitialSearchBoxState(), action) => {
    switch (action.type) {
        case SET_SEARCH_QUERY:
            return { ...state, queryString: action.queryString };
        default:
            return state;
    }
};

const searchResult = (state = getInitialSearchResultState(), action) => {
    switch (action.type) {
        case SEARCH_REQUESTED:
            return { ...state, loading: true };
        case SEARCH_LOADED:
            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                visibleHits: action.searchResult.accumulatedHits,
                totalHits: action.searchResult.totalHits,
                canLoadMore: action.searchResult.canLoadMore,
            };
        case LOADED_MORE: {
            const obj = action.searchResult;
            if (state.items) {
                obj.Hits = [...state.items, ...action.searchResult.hits];
            }
            setSessionStorageResult('konsregResult', obj);

            return {
                ...state,
                loading: false,
                items: action.searchResult.hits,
                page: action.searchResult.page,
                visibleHits: action.searchResult.accumulatedHits,
                totalHits: action.searchResult.totalHits,
                canLoadMore: action.searchResult.canLoadMore,
            };
        }
        default:
            return state;
    }
};

// Combine and export

const konsregReducer = combineReducers({
    settings,
    searchBox,
    searchResult,
});

export default konsregReducer;
