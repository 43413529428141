import {
    PREDEFINED_REPORT_FAILED,
    PREDEFINED_REPORT_RECEIVED,
    PREDEFINED_REPORT_REQUESTED,
    predefinedReports,
} from '../actions/konsregPredefinedReportsActions';

export const predefinedReportStates = {
    NotRequested: 'PREDEFINED_REPORT_NOT_REQUESTED',
    Loading: 'PREDEFINED_REPORT_LOADING',
    Loaded: 'PREDEFINED_REPORT_LOADED',
    Failed: 'PREDEFINED_REPORT_FAILED',
};

export function getInitialState() {
    return Object.values(predefinedReports).reduce(
        (accumulator, key) => ({ ...accumulator, [key]: predefinedReportStates.NotRequested }),
        {}
    );
}

function update(oldState, newState) {
    return { ...oldState, ...newState };
}

export function reducer(state = getInitialState(), action) {
    switch (action.type) {
        case PREDEFINED_REPORT_REQUESTED:
            return update(state, { [action.payload.reportType]: predefinedReportStates.Loading });
        case PREDEFINED_REPORT_RECEIVED:
            return update(state, { [action.payload.reportType]: predefinedReportStates.Loaded });
        case PREDEFINED_REPORT_FAILED:
            return update(state, { [action.payload.reportType]: predefinedReportStates.Failed });
        default:
            return state;
    }
}
