import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import KonsregCustomReport from './konsreg/konsregCustomReport';
import KonsregPredefinedReports from './konsreg/konsregPredefinedReports';
import konsregReportsReducer, { getInitialState } from './reducers/konsregReportsReducer';
import { window } from './util/ssrBase';

export const NAME = 'konsregReports';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const KonsregReports = () => (
    <div>
        <KonsregCustomReport />
        <KonsregPredefinedReports />
    </div>
);

export function init() {
    const initialState = getInitialState();

    const store = createStore(
        konsregReportsReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk))
    );

    const targets = [{ rootElemId: 'js-konsregCustomReport', component: KonsregReports }];

    targets.forEach((e) => {
        const rootElement = document.getElementById(e.rootElemId);
        if (!rootElement) {
            return;
        }
        render(
            <Provider store={store}>
                <e.component {...e.props} />
            </Provider>,
            rootElement
        );
    });
}
