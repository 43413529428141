import { combineReducers } from 'redux';
import * as customReport from './konsregCustomReportReducer';
import * as predefinedReports from './konsregPredefinedReportsReducer';

export function getInitialState() {
    return {
        customReport: customReport.getInitialState(),
        predefinedReports: predefinedReports.getInitialState(),
    };
}

export default combineReducers({
    customReport: customReport.reducer,
    predefinedReports: predefinedReports.reducer,
});
