import React from 'react';

interface Props {
    id: string;
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    invalid?: boolean;
}
export const Checkbox: React.FC<Props> = ({ id, checked, onChange, invalid, children }) => (
    <span className="checkbox--custom">
        <input
            aria-invalid={invalid}
            type="checkbox"
            name={id}
            id={id}
            checked={checked}
            onChange={onChange}
        />
        <label htmlFor={id}>
            <span>{children}</span>
        </label>
    </span>
);
