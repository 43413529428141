import PropTypes from 'prop-types';
import React from 'react';
import { trackEvent } from '../eventTracking';

const KonsregSearchResultItem = ({ typeDescription, name, country, url, searchRank }) => (
        <div className='border-0 border-black-100 border-b-4 py-6 mb-6'>
        <div className="flex flex-row justify-between text-sm">
                <span className="text-yellow-600">
                    {typeDescription}
                </span>
                <span className="text-black-400">{country}</span>
            </div>
        <a
            className="text-bluegreen-500 hover:no-underline underline decoration-1 underline-offset-4"
            href={url}
            onClick={() => trackEvent('Valgt søketreff', searchRank)}
        >
            
            <h3 className=" text-xl font-semibold mb-2">{name}</h3>
        </a>
        </div>
    );


KonsregSearchResultItem.propTypes = {
    name: PropTypes.string.isRequired,
    typeDescription: PropTypes.string.isRequired,
    published: PropTypes.string,
    url: PropTypes.string.isRequired,
    country: PropTypes.string,
    searchRank: PropTypes.number.isRequired,
};

export default KonsregSearchResultItem;
