import PropTypes from 'prop-types';
import React from 'react';
import { translateLang } from '../translate';

const LoadMore = (props) => (
    <div className='flex justify-center'>
        <button
            name={translateLang('newsarchive.buttons.loadMore', props.language)}
            className="items-center flex-inline btn btn--secondary"
            onClick={() => {
                props.onLoadMoreClick();
                const scrollPos = window.scrollY;
                /*
                 * NET-611: Following an update to Chrome and Edge we now stay at the bottom when pressing "Load more".
                 * Instead we wish to stay where we were before pressing the button so we can scroll further down in the results.
                 * In order to do so we have to scroll ourselves back up on button click.
                 * For this to work we need a timeout so the page can have time to fetch data and expand before we scroll.
                 * Alternatively, we could make use of redux to check whether the data is finished loaded or not, but this introduces unnecessary complexity in this matter
                 */
                setTimeout(() => {
                    window.scrollTo(0, scrollPos);
                }, 150);
            }}
        >
            {translateLang('newsarchive.buttons.loadMore', props.language)}
            <span className='icon icon--expand__more' />
        </button>
    </div>
);

LoadMore.propTypes = {
    onLoadMoreClick: PropTypes.func.isRequired,
    language: PropTypes.string
};

export default LoadMore;
