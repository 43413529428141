import PropTypes from 'prop-types';
import React from 'react';

function mapFilterToOptions(filter) {
    return (
        <option key={filter.id} value={filter.id}>
            {filter.name}
        </option>
    );
}

const KonsregSelectList = (props) => {
    const { filters, groups, onSelect } = props;

    const options = groups
        ? groups.map((group) => {
              const children = filters.filter((f) => f.group === group).map(mapFilterToOptions);
              return (
                  <optgroup key={group} label={group}>
                      {children}
                  </optgroup>
              );
          })
        : filters.map(mapFilterToOptions);

    return (
        <select
            aria-label={props.label}
            aria-invalid={String(!!props.invalid)}
            className="border border-bluegreen-400"
            onChange={(e) => onSelect(e.target.value, e.target[e.target.selectedIndex].textContent)}
            value={props.activeFilter}
        >
            <option value="">{props.defaultOptionText}</option>
            {!groups && <option disabled="disabled">────────</option>}
            {options}
        </select>
    );
};

export default KonsregSelectList;

KonsregSelectList.propTypes = {
    onSelect: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    defaultOptionText: PropTypes.string.isRequired,
    activeFilter: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    groups: PropTypes.array,
    invalid: PropTypes.bool,
};
